export const FieldTypes = {
  TEXT_FIELD: "text-field",
  TEXT_AREA: "text-area",
  PHONE_NUMBER_FIELD: "phone-number-field",
  ZIP_CODE_FIELD: "zip-code-field",
  NUMERIC_FIELD: "numeric-field",
  DATE_PICKER: "date-picker",
  TIME_INPUT: "time-input",
  RADIO_GROUP: "radio-group",
  CHECKBOX: "checkbox",
  STANDARD_SELECT: "standard-select",
  MULTIPLE_SELECT: "multiple-select",
  AUTOCOMPLETE: "autocomplete",
};
